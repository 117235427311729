@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "DM Sans", sans-serif,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* 1149-802 */
@media (min-height: 800px) and (max-height: 834px) {
  .synxLogo {
    height: 110% !important;
    width: 110% !important;
  }
  .boxHeight {
    height: 2.2rem !important;
  }

  .buttonText {
    font-size: 0.75rem !important;
  }
  .buttonIcon {
    font-size: 1.2rem !important;
  }
  .buttonImg {
    height: 18px !important;
    width: 18px !important;
  }
  .textSize {
    font-size: 0.85rem !important;
    display: flex !important;
    align-items: center !important;
  }
  .textSizeBottom {
    font-size: 0.7rem !important;
    display: flex !important;
    align-items: center !important;
  }
  .overViewHeading {
    margin-top: 2rem !important;
    margin-bottom: 0rem !important;
  }
  .searchField {
    width: 100% !important;
    margin-top: 0.3rem !important;
  }
  .uploadBox {
    height: 14rem !important ;
  }
  .StyledInputBaseSearch {
    width: 89% !important;
  }
  .gridMargin {
    margin-top: 1.2rem !important;
  }
  .selectBox {
    width: 130px !important;
    font-size: 12px !important;
  }
  .filUploadIcon {
    font-size: 1.4rem !important;
  }
  .fileUploadTextSize {
    font-size: 0.65rem !important;
  }
  .imgSizeWalletIcon {
    height: 20px !important;
    width: 20px !important;
  }

  .pieChartImage {
    height: 10.8rem !important;
    width: 10.5rem !important;
  }
  .textPieTop {
    position: absolute !important;
    top: 4.3rem !important;
    font-size: 2rem !important;
  }
  .textPieTopUtilize {
    position: absolute !important;
    top: 7rem !important;
    font-size: 1rem !important;
  }
  .buttonMarginRightBar {
    margin-bottom: 1.8rem !important;
    width: 66% !important;
  }
  /* //// */
  .rightBarAllfilesMargin {
    margin-top: 1.8rem !important;
    padding-left: 0rem !important;
  }
  .logoRightBarAllFiles {
    height: 60px !important;
    width: 60px !important;
  }
  .pinnedDataHeading {
    margin-top: 2rem !important;
    margin-bottom: 0.8rem !important;
  }
  .threeDotsIcon {
    margin-left: 0.5rem !important;
  }
  .tableHeiht {
    /* height: 330px !important; */
    height: 70vh !important;
  }
  .buttonBoxAllfilesText {
    font-size: 0.76rem !important;
  }
  .lock {
    position: absolute;
    width: 107px;
    bottom: 1% !important;
  }
  .rocker {
    position: absolute;
    width: 114px;
    bottom: -4% !important;
    right: 23%;
  }
}

/* 1181-836 */
@media (min-height: 835px) and (max-height: 935px) {
  .synxLogo {
    height: 110% !important;
    width: 110% !important;
  }
  .boxHeight {
    height: 2.2rem !important;
  }
  .buttonText {
    font-size: 0.85rem !important;
  }
  .buttonIcon {
    font-size: 1.2rem !important;
  }
  .buttonImg {
    height: 18px !important;
    width: 18px !important;
  }
  .textSize {
    font-size: 0.9rem !important;
    display: flex !important;
    align-items: center !important;
  }
  .textSizeBottom {
    font-size: 0.7rem !important;
    display: flex !important;
    align-items: center !important;
  }
  .overViewHeading {
    margin-top: 2rem !important;
    margin-bottom: 0rem !important;
  }
  .searchField {
    width: 100% !important;
    margin-top: 0.3rem !important;
  }
  .uploadBox {
    height: 16rem !important ;
  }
  .StyledInputBaseSearch {
    width: 90% !important;
  }
  .gridMargin {
    margin-top: 1.1rem !important;
  }
  .selectBox {
    width: 130px !important;
    font-size: 12px !important;
  }
  .filUploadIcon {
    font-size: 1.4rem !important;
  }
  .fileUploadTextSize {
    font-size: 0.65rem !important;
  }
  .imgSizeWalletIcon {
    height: 20px !important;
    width: 20px !important;
  }

  .pieChartImage {
    height: 12.5rem !important;
    width: 12rem !important;
  }
  .textPieTop {
    position: absolute !important;
    top: 4.8rem !important;
    font-size: 2.2rem !important;
  }
  .textPieTopUtilize {
    position: absolute !important;
    top: 7.8rem !important;
    font-size: 1.1rem !important;
  }
  .gifImg {
    position: absolute !important;
    top: 67.5% !important;
  }
  .buttonMarginRightBar {
    margin-bottom: 1.8rem !important;
    width: 66% !important;
  }
  .rightBarAllfilesMargin {
    margin-top: 1.8rem !important;
    padding-left: 0rem !important;
  }
  .logoRightBarAllFiles {
    height: 60px !important;
    width: 60px !important;
  }
  .pinnedDataHeading {
    margin-top: 2rem !important;
    margin-bottom: 0.8rem !important;
  }
  .threeDotsIcon {
    margin-left: 0.5rem !important;
  }
  .tableHeiht {
    /* height: 370px !important; */
    height: 70vh !important;
  }
  .buttonBoxAllfilesText {
    font-size: 0.8rem !important;
  }
  .personalVaultPosition {
    position: absolute !important;
    top: 90% !important;
  }
  .lock {
    position: absolute;
    width: 107px;
    bottom: 1% !important;
  }
  .rocker {
    position: absolute;
    width: 114px;
    bottom: -4% !important;
    right: 23%;
  }
}

@media (min-height: 936px) and (max-height: 1025px) {
  .synxLogo {
    height: 110% !important;
    width: 110% !important;
  }
  .boxHeight {
    height: 2.6rem !important;
  }
  .buttonText {
    font-size: 1rem !important;
  }
  .buttonIcon {
    font-size: 1.4rem !important;
  }
  .buttonImg {
    height: 20px !important;
    width: 20px !important;
  }
  .textSize {
    font-size: 1rem !important;
    display: flex !important;
    align-items: center !important;
  }
  .textSizeBottom {
    font-size: 0.8rem !important;
    display: flex !important;
    align-items: center !important;
  }
  .overViewHeading {
    margin-top: 2rem !important;
    margin-bottom: 0rem !important;
  }
  .searchField {
    width: 100% !important;
    margin-top: 0.3rem !important;
  }
  .uploadBox {
    height: 22rem !important ;
  }
  .StyledInputBaseSearch {
    width: 90% !important;
  }
  .gridMargin {
    margin-top: 0.8rem !important;
    margin-bottom: 0rem !important;
  }
  .selectBox {
    width: 145px !important;
    font-size: 14px !important;
  }
  .filUploadIcon {
    font-size: 1.4rem !important;
  }
  .fileUploadTextSize {
    font-size: 0.65rem !important;
  }
  .imgSizeWalletIcon {
    height: 20px !important;
    width: 20px !important;
  }

  .pieChartImage {
    height: 15rem !important;
    width: 14rem !important;
  }
  .textPieTop {
    position: absolute !important;
    top: 6rem !important;
    font-size: 2.5rem !important;
  }
  .textPieTopUtilize {
    position: absolute !important;
    top: 9.2rem !important;
    font-size: 1.1rem !important;
  }
  .gifImg {
    position: absolute !important;
    top: 68.5% !important;
  }
  .buttonMarginRightBar {
    margin-bottom: 1.8rem !important;
    width: 72% !important;
  }
  .rightBarAllfilesMargin {
    margin-top: 1.8rem !important;
    padding-left: 0rem !important;
  }
  .logoRightBarAllFiles {
    height: 60px !important;
    width: 60px !important;
  }
  .pinnedDataHeading {
    margin-top: 2rem !important;
    margin-bottom: 0.8rem !important;
  }
  .threeDotsIcon {
    margin-left: 0.5rem !important;
  }
  .tableHeiht {
    /* height: 470px !important; */
    height: 70vh !important;
  }
  .buttonBoxAllfilesText {
    font-size: 0.8rem !important;
  }
  .personalVaultPosition {
    position: absolute !important;
    top: 91% !important;
  }
  .lock {
    position: absolute;
    width: 107px;
    bottom: 1% !important;
  }
  .rocker {
    position: absolute;
    width: 114px;
    bottom: -4% !important;
    right: 23%;
  }
  .mapboxgl-popup {
    max-width: 200px;
  }
  
  .mapboxgl-popup-content {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
  }
}
