@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

.h-100vh {
  height: 100vh;
  overflow: hidden;
}
.loginpage ul li img {
  width: 42px;
  margin: 4px 7px;
}

.marker {
  background-image: url('https://shdw-drive.genesysgo.net/7RwSs3vo3bP3LZ1n3ifBzrtq2wF2CB1fSKSfBMng2HBi/lock-image-marker.png');
  background-size: cover;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.closed-banner {
  width: 40px;
  height: 40px;
  transition: width 2s, height 2s;
}

.from-purple-new {
  --tw-gradient-from: #6C5DD3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(108, 93, 211, 0));
}

.to-blue-new {
  --tw-gradient-to: #2C2658;
}
.bgdark {
  font-family: "DM Sans", sans-serif;
  background-color: #121212;
  color: white;
}
.loginpage .input-box {
  margin: 10px 0px;
  width: 100%;
}

.bglogin {
  height: 59vh;
  width: 100%;
  position: relative;
  object-fit: contain;
}
img.shadowbg {
  position: absolute;
  top: -57%;
  width: 100%;
  opacity: 0.5;
  filter: blur(10px);
  z-index: 0;
}
.w-80 {
  width: 73%;
}
.header .navbar-nav img {
  width: 23px;
}
nav.header .container-fluid {
  padding: 0 3rem;
}
.header .navbar-nav a {
  margin: 0 12px;
}
.header a.navbar-brand img {
  width: 52px;
}
img.lock {
  position: absolute;
  width: 107px;
  bottom: -8%;
  left: 20%;
}
img.rocker {
  position: absolute;
  width: 114px;
  bottom: -7%;
  right: 23%;
}
.loginpage .label {
  font-size: 15px;
  margin-bottom: 5px;
}
.loginpage .input {
  background-color: #f6f6f600;
  padding: 0px 5px;
  border: 2px solid rgb(216 216 216 / 36%);
  border-radius: 10px;
  overflow: hidden;
  margin: 7px 0px;
}
.loginpage .input-box input {
  width: 92%;
}
.loginpage input {
  border: none;
  outline: none;
  font-size: 15px;
  padding: 7px 5px;
  background-color: #f6f6f600;
  color: white;
}
.or {
  text-align: center;
  position: relative;
  margin: 10px 2px 12px;
  font-size: 15px;
}
.or:before,
.or::after {
  right: 0;
  top: 10px;
  width: 44%;
  background: white;
  height: 1px;
  content: "";
  position: absolute;
}
.or:before {
  right: 0;
}
.or::after {
  left: 0;
}
.loginpage .input i {
  color: #767676;
  font-size: 15px;
}
.loginpage .logo p {
  margin: 2rem 0 2.7rem;
  font-size: 20px;
}

.loginpage .btn {
  background: #6c5dd3;
  margin-top: 17px;
  margin-bottom: 13px;
  transition: all 0.3s linear;
}
.purple {
  color: #6c5dd3;
}
.rightlogo {
  width: 280px;
}
.loginpage .btn:hover {
  transform: translateY(-2px);
}
.loginpage .extra-line {
  font-size: 15px;
}
.textpink {
  color: #ffa2c0;
}
.loginpage .logo img {
  width: 84px;
}

.checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  align-items: center;
}

.checkbox > span {
  padding: 0 0.45rem;
}

.checkbox > input {
  height: 16px;
  width: 16px;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #8e8e8e;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  cursor: pointer;
}

.checkbox > input:checked {
  border: 1px solid #8e8e8e;
  background-color: #1e1e1e;

  z-index: 1;
  opacity: 0.5;
}

.checkbox > input:checked + span::before {
  content: "\2713";
  display: block;
  text-align: center;
  color: #c8c0ff;
  position: absolute;
  left: 0.2rem;
  top: 0;
}

.checkbox > input:active {
  border: 2px solid #8e8e8e;
}

.Map {
  height: 100vh;
  width: 100vw;
}

.map-container {
  height: 100%;
  width: 100%;
}

/* @media only screen and (max-width: 1490px) {
  .bglogin {
    height: 64vh;
  }
  .w-80 {
    width: 92%;
  }
  img.rocker {
    width: 128px;
    bottom: -2%;
    right: 20%;
  }
  img.lock {
    width: 134px;
    bottom: -7%;
    left: 15%;
  }
  .loginpage {
    padding: 3rem 4rem 0 1rem;
  }

  .loginpage ul li img {
    width: 51px;
    margin: 13px 9px;
  }
  .loginpage .btn {
    background: #6c5dd3;
    margin-top: 17px;
    margin-bottom: 13px;
    transition: all 0.3s linear;
    padding: 11px;
    font-size: 19px;
  }
} */
/* @media only screen and (max-width: 1344px) and (max-height: 938px) {
  .spacingright {
    padding-left: 0;
  }
  .w-80 {
    width: 93%;
  }
  .header {
    margin-bottom: 1rem;
  }
  .loginpage {
    padding: 3rem 3rem 0 1rem;
  }
  .loginpage .input-box input {
    width: 94%;
  }
  img.lock {
    bottom: -3%;
  }
  img.rocker {
    bottom: 3%;
  }
  img.shadowbg {
    top: -34%;
  }
  .bglogin {
    height: 60vh;
  }
} */
/* @media only screen and (max-width: 1182px) and (max-height: 840px) {
  .loginpage .input-box {
    margin: 8px 0px;
  }
  .loginpage .logo p {
    margin: 2rem 0 2rem;
    font-size: 20px;
  }
  .loginpage ul li img {
    width: 43px;
    margin: 9px 9px 2px;
  }
  img.rocker {
    bottom: 0%;
  }
  img.lock {
    bottom: -4%;
  }
  .or {
    margin: 0px 2px 9px;
  }
  .loginpage .btn {
    padding: 8px;
  }
} */
/* @media only screen and (max-width: 1149px) and (max-height: 802px) {
  .loginpage {
    padding: 3rem 2rem 0 1rem;
  }
  img.rocker {
    bottom: -3%;
  }
  img.lock {
    bottom: -7%;
  }
  .loginpage input {
    padding: 5px 5px;
  }
  .loginpage .logo p {
    margin: 2rem 0rem 1.7rem;
  }
  .loginpage .btn {
    padding: 6px;
  }
  .bglogin {
    height: 58vh;
  }

  .loginpage .input {
    margin: 5px 0px 0;
  }
  .loginpage .input-box {
    margin: 10px 0px 6px;
  }
  .or {
    margin: 2px 2px 9px;
  }
  .loginpage ul li img {
    width: 42px;
    margin: 11px 9px 0;
  }
} */
.button1 img{
  width: 130px;
}
.button1 button {
  color: white;
  font-size: 18px;
  border: 0;
  padding: 7px 30px;
  border-radius: 5px;
  margin: 4px 16px;
}
.copybtn{
  background-color: #6c5dd3;
}

.sharebtn{
  background-color: #C96183;
}
.sendcyptoicon{
  width: 36px;
  margin-right: 9px;
  float: left;
  border-radius: 100px;
}
.f-18{
  font-size: 18px;
}
.text-gray{
  color: gainsboro;
}
.w-85{
  margin: 0 auto;
  width: 85%;
}
.w-90{
  margin: 0 auto;
  width: 90%;
}
.bgclr{
  background-color: #282832;
  width: 89%;
  margin: 0 auto;
  padding: 16px 20px;
  border-radius: 12px;
}
.cyptoicon{
  display: flex;
  place-items: flex-start;
  align-items: center;
}
.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}
